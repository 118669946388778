@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes loading {
  0% {
    background-color: #C7315C;
    box-shadow: 12px 0 0 0 rgba(199, 49, 92, 0.2), 24px 0 0 0 rgba(199, 49, 92, 0.2);
  }
  25% {
    background-color: rgba(199, 49, 92, 0.4);
    box-shadow: 12px 0 0 0 #c7315c, 24px 0 0 0 rgba(199, 49, 92, 0.2);
  }
  75% {
    background-color: rgba(199, 49, 92, 0.4);
    box-shadow: 12px 0 0 0 rgba(199, 49, 92, 0.2), 24px 0 0 0 #C7315C;
  }
}
html {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 16px;
}
body {
  margin: 0;
}
body.-is-open {
  overflow: hidden;
}
body.-is-open .bg > div {
  opacity: 1;
}
a,
.cta {
  cursor: pointer;
  display: block;
  text-decoration: none;
  font-weight: normal;
  position: relative;
  color: #000000;
  transition: color 0.5s;
  margin: 6px 12px;
  padding: 0;
}
a:hover,
.cta:hover {
  color: rgba(199, 49, 92, 0.9);
}
a:focus,
.cta:focus {
  color: #9e2749;
}
button {
  all: unset;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 12px;
}
h1 {
  color: #C7315C;
  font-size: 32px;
  line-height: 40px;
  margin: 24px 12px;
}
@media only screen and (max-width: 960px) {
  h1 {
    font-size: 24px;
    line-height: 32px;
  }
}
h2 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
}
h3 {
  font-size: 20px;
  line-height: 28px;
}
p {
  font-size: 16px;
  line-height: 28px;
  color: #271438;
  margin: 24px 12px;
}
ul {
  display: flex;
  line-height: 20px;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style-type: none;
  padding: 0;
  margin: 6px 9px;
}
ul li {
  display: flex;
  box-sizing: border-box;
  flex-basis: 33.33333333%;
  align-items: center;
  color: #C7315C;
  padding: 6px 3px;
}
ul li svg {
  margin-right: 4px;
}
dl {
  font-size: 14px;
  line-height: 20px;
  margin: 18px 12px;
}
dl dt {
  margin-bottom: 6px;
}
dl dd {
  color: #C7315C;
  display: inline;
  margin: 0;
  padding: 0;
}
dl dd:not(:last-child)::after {
  content: ', ';
}
img,
svg {
  width: auto;
  display: block;
  -webkit-user-drag: none;
}
svg {
  fill: currentColor;
  height: 24px;
  width: 24px;
}
main {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  width: 100%;
  z-index: 2;
}
section {
  padding: 48px 24px;
  border-bottom: 1px solid rgba(39, 20, 56, 0.1);
}
section.intro {
  padding-top: 96px;
  padding-bottom: 96px;
}
section:last-child {
  border-bottom: 0;
}
@media only screen and (max-width: 960px) {
  section {
    padding: 30px 12px;
  }
  section.intro {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}
.page {
  box-sizing: border-box;
  background-color: #FFFFFF;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}
.container {
  max-width: 960px;
  margin: 0 auto;
}
.bg {
  background-image: url('/img/default-bg.jpg');
  background-size: cover;
  position: fixed;
  width: 110%;
  height: 100vh;
  top: 0;
  left: 0;
}
.bg-side {
  width: 25%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 75%;
  overflow: hidden;
  z-index: 1;
}
.bg-side-img {
  width: 400%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-about {
  background-image: url('/img/about-bg.jpg');
}
.bg-frontage {
  background-image: url('/img/frontage-bg.jpg');
}
.bg-healthshare {
  background-image: url('/img/healthshare-bg.jpg');
}
.bg-montana {
  background-image: url('/img/montana-bg.jpg');
}
.bg-comber {
  background-image: url('/img/comber-bg.jpg');
}
.bg-budget {
  background-image: url('/img/budget-bg.jpg');
}
.bg-make-up-your-vote {
  background-image: url('/img/make-up-your-vote-bg.jpg');
}
.view {
  width: 75%;
  flex: 0 0 75%;
  min-height: 100vh;
}
.view + .view {
  position: fixed;
  top: 0;
  left: 100%;
  height: 100%;
  z-index: 1;
}
.view + .view .viewport {
  height: 100%;
  overflow: hidden;
}
.-is-open .view + .view .viewport {
  overflow-y: auto;
}
.view + .view .page {
  margin-left: auto;
}
.view + .view h3 {
  color: #C7315C;
  font-size: 20px;
}
@media only screen and (max-width: 960px) {
  .view {
    width: 90%;
    flex: 0 0 90%;
  }
  .view:first-child h3::before {
    margin-top: 42px;
    margin-bottom: 42px;
  }
}
.profile {
  display: flex;
}
.profile img,
img.profile {
  border-radius: 50%;
  margin: 12px;
  height: 108px;
  width: 108px;
}
.profile h1 {
  margin-top: 12px;
}
.profile p {
  align-self: center;
  color: #000000;
  margin: 0 0 0 18px;
  font-size: 20px;
  font-weight: 300;
}
@media only screen and (max-width: 960px) {
  .profile {
    display: block;
  }
  .profile img,
  img.profile {
    height: 84px;
    width: 84px;
    margin: 12px 12px 12px 6px;
  }
}
.showcase {
  display: flex;
  flex-flow: row wrap;
}
.showcase img {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
  max-width: 600px;
  min-width: 240px;
  height: 100%;
  width: 50%;
}
.panel {
  border-radius: 3px;
  position: relative;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #000000;
  display: flex;
  padding: 30px;
  margin: 12px;
  animation: fadeIn 0.625s ease-in;
  transition: filter 0.5s;
}
.panel h3 {
  display: flex;
  flex-direction: column;
}
.panel span {
  font-size: 14px;
  font-weight: 300;
}
.panel h3,
.panel span {
  margin: 0;
  color: #FFFFFF;
  z-index: 1;
}
@media (hover: hover) {
  .panel:hover {
    filter: brightness(1.5);
    color: #000000;
  }
}
@media only screen and (max-width: 960px) {
  .panel {
    padding-right: 18px;
    padding-left: 18px;
  }
}
.about .profile img {
  height: 204px;
  width: 204px;
}
.about h2 {
  font-size: 21px;
  color: #C7315C;
}
.about h3 {
  margin-top: 24px;
}
.about h3 span {
  color: rgba(39, 20, 56, 0.6);
  font-size: 16px;
}
.about h3 + p {
  margin-top: 12px;
  margin-bottom: 12px;
}
.about ul {
  list-style-type: disc;
  padding-left: 36px;
}
.about ul li {
  display: list-item;
}
.about p + ul {
  display: block;
}
.about ul li {
  color: #271438;
}
@media only screen and (max-width: 960px) {
  .about ul {
    display: block;
  }
  .about h2::before {
    margin-top: 42px;
    margin-bottom: 42px;
  }
}
.social {
  display: flex;
  margin: 48px 12px 24px;
}
.social a {
  color: #271438;
  margin: 4px;
}
.social a:first-child {
  color: #FFFFFF;
  margin-left: auto;
}
.social a:first-child svg path {
  transition: stroke 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  stroke: #271438;
}
.social a:first-child svg:hover {
  color: transparent;
}
.social a:first-child svg:hover path {
  stroke: #C7315C;
}
.social svg {
  height: 30px;
  width: 30px;
  transition: transform 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95), color 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.social svg:hover {
  color: #C7315C;
  transform: scale(1.1);
}
.close {
  border-radius: 50% 0 0 50%;
  background-color: rgba(199, 49, 92, 0.9);
  box-sizing: border-box;
  position: absolute;
  margin: 0;
  color: #FFFFFF;
  width: 114px;
  height: 114px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  transition: background-color 0.5s;
}
.close svg {
  fill: currentColor;
  height: 36px;
  width: 36px;
}
.close:hover {
  background-color: rgba(255, 255, 255, 0.85);
  color: #C7315C;
}
.close:focus {
  background-color: rgba(255, 255, 255, 0.9);
  color: #b32c53;
}
@media only screen and (max-width: 960px) {
  .close {
    border-radius: 0;
    height: 93px;
    width: 11%;
    transform: translateY(0);
    top: 0;
  }
  .close svg {
    height: 27px;
    width: 27px;
  }
}
